
import {
  Component,
  Inject,
  InjectReactive,
  Mixins,
  Ref,
  Vue,
} from "vue-property-decorator";
import TopAppBar from "@/components/base/TopAppBar.vue";
import { IBattle } from "@/interfaces/battle.interface";
import {
  BattleFragment,
  UserItemWithoutUserFragment,
} from "@/graphql/fragments";
import { useFindOne } from "@/graphql/use-find-one";
import gql from "graphql-tag";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";
import BattleCard from "@/components/battles/BattleCard.vue";
import BattleUser from "@/components/battles/BattleUser.vue";
import AppTape from "@/components/app/AppTape.vue";
import BattleBox from "@/components/battles/BattleBox.vue";
import Price from "@/components/base/Price.vue";
import { COLORS } from "@/components/battles/constants";
import BattleUserSum from "@/components/battles/BattleUserSum.vue";
import BattleTape from "@/components/battles/BattleTape.vue";
import { useFindAll } from "@/graphql/use-find-all";
import { UserItemEntity } from "@/entities/user-item.entity";
import * as chunk from "lodash/chunk";
import * as range from "lodash/range";
import * as sumBy from "lodash/sumBy";
import ItemList from "@/components/items/ItemList.vue";
import Item from "@/components/items/Item.vue";

@Component({
  components: {
    TopAppBar,
    BattleCard,
    BattleUser,
    BattleTape,
    BattleBox,
    Price,
    BattleUserSum,
    ItemList,
    Item,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm["prevPath"] = from.path;
    });
  },
  apollo: {
    battle: {
      skip() {
        return (
          !!this.battle ||
          !!this.$route.query.join ||
          this.findAllGunBox.length === 0
        );
      },
      update(data) {
        return data.findOneBattle;
      },
      query: useFindOne("Battle", BattleFragment),
      variables() {
        return {
          filter: {
            _id: { eq: this.$route.params.id },
          },
          options: {
            populate: "users",
          },
        };
      },
    },
    profitItems: {
      skip() {
        return !this.battle || !this.battle.profitUserItemIds.length;
      },
      update(data) {
        return (data.findAllUserItem || []).map((v) => new UserItemEntity(v));
      },
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      variables() {
        return {
          filter: {
            _id: { in: this.battle.profitUserItemIds },
          },
          options: {
            limit: 40
          }
        };
      },
    },
    $subscribe: {
      battleUpdated: {
        query: gql`
          subscription ($battleId: ID) {
            subscribeBattleUpdated(battleId: $battleId) {
              ...Battle
            }
          }
          ${BattleFragment}
        `,
        variables() {
          return {
            battleId: this.$route.params.id,
          };
        },
        result({ data: { subscribeBattleUpdated: value } }) {
          const oldBattle = this.battle;
          this.battle = value;

          if (oldBattle && !oldBattle.completed && value.completed) {
            this.$nextTick(() => this.animate());
          }
        },
      },
      battleRemoved: {
        query: gql`
          subscription ($battleId: ID) {
            subscribeBattleRemoved(battleId: $battleId) {
              _id
            }
          }
        `,
        variables() {
          return {
            battleId: this.$route.params.id,
          };
        },
        result({ data: { subscribeBattleRemoved: value } }) {
          if (value) {
            this.$notify({
              type: "info",
              text: "Сражение было отменено, деньги возвращены на Ваш баланс, приносим свои извенения за неудобства.",
            });

            this.$router.replace("/battle");
          }
        },
      },
    },
  },
})
export default class BattlePage extends Mixins(FindAllGunBoxMixin) {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @Ref("tapes") tapes!: any[];

  private profitItems: UserItemEntity[] = [];
  private userColors = Object.freeze(COLORS);
  private prevPath: string | null = null;
  private inAnimation = false;
  private inAnimationWithPause = false;
  private skipping = false;
  private battleTapeHeight = 255;
  private currentRoundIndex = 0;
  private battle: IBattle | null =
    this.$apollo.provider.defaultClient.readFragment({
      id: "Battle:" + this.$route.params.id,
      fragmentName: "Battle",
      fragment: BattleFragment,
    });

  get animatedProfitItemsSum() {
    return Number(sumBy(this.animatedProfitItems, "price").toFixed(2));
  }

  get animatedProfitItemsSumPerUser() {
    if (!this.battle || !this.animatedProfitItems.length) {
      return [];
    }

    let sums: number[] = new Array(this.battle.userCount).fill(0);

    chunk(this.animatedProfitItems.reverse(), this.battle.userCount).forEach(
      (roundItems) => {
        roundItems.forEach((item, index) => {
          sums[index] += item.price;
        });
      }
    );

    return sums.map((v) => Number(v.toFixed(2)));
  }

  async animate() {
    // Ждем загрузки предметов если их нет
    if (this.$apollo.queries.profitItems.loading) {
      await new Promise<void>((resolve) => {
        const unwatch = this.$watch(
          "$apollo.queries.profitItems.loading",
          (value) => {
            if (!value) {
              unwatch();
              resolve();
            }
          }
        );
      });
    }

    if (!this.battle) {
      return;
    }

    this.inAnimation = true;
    this.inAnimationWithPause = true;
    this.currentRoundIndex = 0;
    const roundsContainer = this.$refs["battleRounds"] as any;

    for await (const i of range(this.battle.gunBoxIds.length)) {
      roundsContainer.scrollLeft = roundsContainer.querySelector(
        `.battle-round:nth-child(${this.currentRoundIndex + 1})`
      ).offsetLeft;
      await this.animateCurrentRound();

      if (this.skipping) {
        break;
      }

      if (this.currentRoundIndex < this.battle.gunBoxIds.length - 1) {
        this.currentRoundIndex++;
      }
    }

    this.inAnimation = false;
    if (!this.skipping) {
      await this.sleep(1000);
    }
    this.inAnimationWithPause = false;
    roundsContainer.scrollLeft = 0;
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get profitItemsMap() {
    return (this.profitItems || []).reduce((acc, v) => {
      acc[v._id] = v;
      return acc;
    }, {});
  }

  async animateCurrentRound(duration?: number) {
    if (!this.battle) {
      return;
    }

    const itemIds = chunk(this.battle.profitUserItemIds, this.battle.userCount)[
      this.currentRoundIndex
    ];

    return Promise.all(
      this.tapes.map((tape, i) =>
        tape.animate(this.profitItemsMap[itemIds[i]], duration)
      )
    );
  }

  get animatedProfitItems() {
    let index =
      this.inAnimation || !this.battle || !this.battle.completed
        ? this.currentRoundIndex - 1
        : this.currentRoundIndex;

    if (index === -1 || !this.battle || !this.profitItems.length) {
      return [];
    }

    const itemIds = chunk(this.battle.profitUserItemIds, this.battle.userCount);

    return range(index + 1)
      .map((i) => itemIds[i].map((_id) => this.profitItemsMap[_id]))
      .flat()
      .reverse();
  }

  async skip() {
    if (!this.battle) {
      return;
    }

    if (!this.profitItems.length) {
      await new Promise<void>((resolve) => {
        const unwatch = this.$watch("profitItems.length", (value) => {
          if (value) {
            unwatch();
            this.$nextTick(() => resolve());
          }
        });
      });
    }

    this.skipping = true;
    await Promise.all(this.tapes.map((tape) => tape.skip()));
    this.currentRoundIndex = this.battle.gunBoxIds.length - 1;
    await this.$nextTick();
    await this.animateCurrentRound(0);
    this.skipping = false;

    const roundsContainer = this.$refs["battleRounds"] as any;
    roundsContainer.scrollLeft = 0;
  }

  get currentGunBoxId() {
    return this.battle?.gunBoxIds[this.currentRoundIndex];
  }

  get canLeaveBattle() {
    return (
      this.battle &&
      !this.battle.completed &&
      this.user &&
      this.battle.userIds.includes(this.user._id)
    );
  }

  get battleSubtitleStatus() {
    if (!this.battle) {
      return `Загрузка...`;
    }

    if (this.skipping) {
      return `Пропускаем сражение`;
    }

    if (this.inAnimation) {
      return `Ведется сражение`;
    }

    if (this.battle.completed) {
      return `Сражение завершено`;
    }

    return `Ожидание игроков ${
      this.battle.userIds.filter((v) => v !== null).length
    } / ${this.battle.userCount}`;
  }

  async mounted() {
    if (this.battle) {
      this.$nextTick(() => !this.battle!.completed || this.skip());
    } else {
      const unwatch = this.$watch("battle", (value) => {
        if (value) {
          unwatch();
          this.$nextTick(() => !value.completed || this.skip());
        }
      });
    }

    if (
      this.$route.query.join &&
      this.prevPath === "/battle" &&
      this.user &&
      (!this.battle || !this.battle.userIds.includes(this.user._id))
    ) {
      await this.join(Number(this.$route.query.join));
    } else if (this.$route.query.join) {
      await this.$router.replace({ query: undefined });
    }
  }

  async share() {
    await navigator.share({
      url: location.href,
    });
  }

  async join(joinIndex?: number) {
    if (!this.user) {
      return this.$router.push("#login");
    } else if (this.battle && this.battle.price > this.user.balance) {
      return this.$router.push(
        `?getAmount=${(this.battle.price - this.user.balance).toFixed(
          2
        )}#deposit`
      );
    }

    await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($battleId: ID!, $joinIndex: Int) {
            joinBattle(battleId: $battleId, joinIndex: $joinIndex) {
              ...Battle
            }
          }
          ${BattleFragment}
        `,
        variables: {
          joinIndex,
          battleId: this.$route.params.id,
        },
      })
      .catch(console.error);

    this.battle = this.$apollo.provider.defaultClient.readFragment({
      id: "Battle:" + this.$route.params.id,
      fragmentName: "Battle",
      fragment: BattleFragment,
    });

    await this.$router.replace({ query: undefined });
  }

  async leave() {
    await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($battleId: ID!) {
            leaveBattle(battleId: $battleId) {
              ...Battle
            }
          }
          ${BattleFragment}
        `,
        variables: {
          battleId: this.$route.params.id,
        },
      })
      .catch(console.error);

    this.battle = this.$apollo.provider.defaultClient.readFragment({
      id: "Battle:" + this.$route.params.id,
      fragmentName: "Battle",
      fragment: BattleFragment,
    });

    await this.$router.push({ path: "/battle" });
  }
}
