
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import AppTape from "@/components/app/AppTape.vue";
import BattleUser from "@/components/battles/BattleUser.vue";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";
import { UserItemEntity } from "@/entities/user-item.entity";
import { useScroll } from "@/graphql/use-scroll";
import {
  AccountShortFragment,
  UserItemShortFragment,
} from "@/graphql/fragments";

@Component({
  components: {
    AppTape,
    BattleUser,
  },
  apollo: {
    items: {
      update(data) {
        const value = data[Object.keys(data)[0]];

        return value ? value.docs : null;
      },
      skip() {
        return !this.box;
      },
      query() {
        return useScroll("UserItem", UserItemShortFragment);
      },
      variables() {
        return {
          options: {
            sort: "-price",
            limit: 50,
          },
          filter: {
            price: {
              gte: this.box.minItemPrice,
              lte: this.box.maxItemPrice,
            },
            isNameFirstUnique: { eq: true },
            state: { eq: "DEFAULT" },
            gunBoxType: { eq: this.box?.type },
          },
        };
      },
    },
  },
})
export default class BattleTape extends Mixins(FindAllGunBoxMixin) {
  @Prop() color!: string;
  @Prop() gunBoxId!: string;
  @Prop() userId!: string;
  @Prop({ type: Boolean, default: false }) loser!: boolean;
  @Ref("tape") tape!: any;

  private items: UserItemEntity[] | null = null;
  private inAnimation = false;
  private skipping = false;

  get box() {
    return this.findAllGunBoxMap[this.gunBoxId];
  }

  async animate(item: { price: number }, duration = 10000) {
    this.inAnimation = true;

    // Ждем загрузки предметов если их нет
    if (this.$apollo.queries.items.loading) {
      await new Promise<void>((resolve) => {
        const unwatch = this.$watch(
          "$apollo.queries.items.loading",
          (value) => {
            if (!value) {
              unwatch();
              resolve();
            }
          }
        );
      });
    }

    if (this.skipping) {

      this.tape.animate(item, duration).catch(console.error);
      await this.tape.skip();
    } else {

      await this.tape.animate(item, duration);
    }

    this.inAnimation = false;
  }

  async skip() {
    if (this.tape && this.inAnimation) {
      this.skipping = true;

      await this.tape.skip();

      if (this.inAnimation) {
        await new Promise<void>((resolve) => {
          const unwatch = this.$watch("inAnimation", (value) => {
            if (!value) {
              unwatch();
              resolve();
            }
          });
        });
      }

      this.skipping = false;
    }
  }
}
